import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// description and date are optional
const BlogCard = ({img, title, link, description, date}) => {
  const image = typeof(img) === 'object' ? getImage(img) : null

  return (
    <article
      className="mx-5 post-list-item"
      itemScope
      itemType="http://schema.org/Article"
    >
      <div className="w-[290px] bg-white rounded-sm overflow-hidden">
        <Link to={link} itemProp="url">
          {image ? 

          <GatsbyImage image={image} alt={title} className="object-cover object-center w-full h-48" itemProp="image" />
          : <img src={img} alt={title} className="object-cover object-center w-full h-48" itemProp="image" />
          }
          <div className="p-5 pb-10">

            <header>
              <h5 className="mb-5 text-xl font-bold leading-6 text-blue-90" data-scroll="out" data-splitting="" itemProp="headline">
                {title}
              </h5>
              <small>{date}</small>
            </header>

            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
                itemProp="description"
              />

              <span className="flex text-sm uppercase hover:underline text-blue-80 item-center space-x-2" itemProp="url">
                <span data-scroll="out" data-splitting="">read more</span>
                <UpRightArrow />
              </span>
            </section>

          </div>
        </Link>
      </div>
    </article>
  )
}

export default BlogCard


const UpRightArrow = () => (
  <svg id="Group_63" data-name="Group 63" xmlns="http://www.w3.org/2000/svg" width="20"
    height="20" viewBox="0 0 20 20">
    <path id="Path_10" data-name="Path 10" d="M0,0H20V20H0Z" fill="none" />
    <line id="Line_4" data-name="Line 4" x1="8" y2="8" transform="translate(6 6)" fill="none"
      stroke="#1743d3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path id="Path_11" data-name="Path 11" d="M8,7h7.446v7.446"
      transform="translate(-1.305 -1.142)" fill="none" stroke="#1743d3" strokeLinecap="round"
      strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
)
