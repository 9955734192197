import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogCard from '../components/blog-card'

const MEDIUM_CDN = "https://cdn-images-1.medium.com/max/400"
const MEDIUM_URL = "https://medium.com"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const repoPosts = data.allMarkdownRemark.nodes.map(post => ({
    title: post.frontmatter.title,
    slug: post.fields.slug,
    link: post.fields.slug,
    img: post.frontmatter.cover,
    date: post.frontmatter.date,
    description: post.frontmatter.description || post.excerpt,
  }))
  const mediumPosts = data.allMediumPost.nodes.map(post => ({
    title: post.title,
    slug: post.uniqueSlug,
    link: `${MEDIUM_URL}/@${post.author.username}/${post.uniqueSlug}`,
    img: `${MEDIUM_CDN}/${post.virtuals.previewImage.imageId}`,
    date: post.createdAt,
    description: post.virtuals.subtitle,
  }))

  const posts = repoPosts.concat(mediumPosts).sort((x, y) => new Date(y.date) - new Date(x.date))

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/news" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <ol className="container flex flex-wrap mx-auto my-4 list-none gap-2">
        {posts.map(post => {
          const title = post.title || post.slug

          return (
            <li key={post.slug}>
              <BlogCard 
                img={post.img}
                title={title}
                link={post.link}
                date={post.date}
                description={post.description} />
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allMediumPost(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        id
        uniqueSlug
        title
        createdAt(formatString: "MMMM DD, YYYY")
        virtuals {
          subtitle
          readingTime
          previewImage {
            imageId
          }
        }
        author {
          username
        }
      }
    }
  }
`
